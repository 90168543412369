import React from "react"
import PropTypes from "prop-types"

import BannerHero from "./BannerHero"

import bannerStyles from "./_banner.module.css"
import BannerContent from "./BannerContent"

const Banner = (props) => {
    
    const { onCourse, onContact } = props
    
    return(
        <div className={bannerStyles.bg_color}>
            <BannerHero onCourse={onCourse} onContact={onContact} />
            <BannerContent />
        </div>
    )
}

BannerHero.propTypes = {
    onContact: PropTypes.func,
    onCourse: PropTypes.func
}

export default Banner
