import React from "react"
import GridLayout from "../gridLayout/GridLayout"

import commonStyles from "../../styles/common.module.css"
import whyUsStyles from "./_whyUs.module.css"

const WhyUsSection = () => {
  return (
    <div className={`${commonStyles.padding_t_100} ${commonStyles.padding_b_50}`}>
      <div className={commonStyles.container}>
        <div className={whyUsStyles.title}>
          <h4>#WhyTalento</h4>
          <h2>
            <strong>Why Study with Talento?</strong>
          </h2>
        </div>
        <div className={whyUsStyles.section}>
          <GridLayout
            applyContainerClassName={false}
          >
            <div className="layout_lg_4">
              <div className={whyUsStyles.section_container}>
                <div className={whyUsStyles.section_image}>
                  <img src={require("../../images/demo_images/grain.svg")} alt="grain"/>
                </div>
                <div className={whyUsStyles.section_content}>
                    <h5><strong>Learn anything</strong></h5>
                  <p>Whether you want to develop as a professional or discover a new hobby. there's an course for you.</p>
                </div>
              </div>
            </div>
            <div className="layout_lg_4">
              <div className={whyUsStyles.section_container}>
                <div className={whyUsStyles.section_image}>
                  <img src={require("../../images/demo_images/pie-chart.svg")} alt="pie-chart"/>
                </div>
                <div className={whyUsStyles.section_content}>
                <h5><strong>Flexible Learning</strong></h5>
                  <p>Get a custom learning plan tailor to fit your busy life. Learn at your own pace and reach your goal.</p>
                </div>
              </div>
            </div>
            <div className="layout_lg_4">
              <div className={whyUsStyles.section_container}>
                <div className={whyUsStyles.section_image}>
                  <img src={require("../../images/skill.svg")} alt="grain"/>
                </div>
                <div className={whyUsStyles.section_content}>
                  <h5><strong>Learn with experts</strong></h5>
                  <p>Get a personal gimpse into careers and mindsets of leading experts who are solving complex challenges.</p>
                </div>
              </div>
            </div>
          </GridLayout>
        </div>
      </div>
    </div>
  )
}

export default WhyUsSection
