import React from "react"
import PropTypes from "prop-types"

import commonStyles from "../../styles/common.module.css"
import whyUsStyles from "./_whyUs.module.css"

const WhyUsSection2 = (props) =>{

    const { onJoinUsClick } = props

    const onClick= () => {
        onJoinUsClick()
    }

    return (
        <section className={whyUsStyles.section2_bg}>
            <div className={commonStyles.container}>
                <div className={[whyUsStyles.title, whyUsStyles.section2_title].join(" ")}>
                    <h2><strong>Find the right learning path for you</strong></h2>
                </div>
                <div className={whyUsStyles.section2_description}>
                    <p>Match your goals to our programs, explore your options and map out your path to sucess</p>
                </div>
                <div className={whyUsStyles.section2_button}>
                    <button onClick={onClick}>Join with us</button>
                </div>
            </div>
        </section>
    )
}

WhyUsSection2.propTypes = {
    onJoinUsClick: PropTypes.func
}

export default WhyUsSection2