import React from "react"
import PropTypes from "prop-types"

import commonStyles from "../../styles/common.module.css"
import "./_gridLayout.css"

const gridLayout = (props) => {
    
    const { containerStyles ,layoutStyles ,className ,children, applyContainerClassName } = props

    return(
        <div className={[applyContainerClassName?(commonStyles.container):null, ...className].join(" ")} style={containerStyles}>
            <div className="layout--row" style={layoutStyles}>
                {children}
            </div>
        </div>
    )
}

gridLayout.defaultProps = {
    className: [],
    applyContainerClassName: false
}

gridLayout.propTypes = {
    containerStyles: PropTypes.object,
    layoutStyles: PropTypes.object,
    children: PropTypes.node,
    className: PropTypes.array,
    applyContainerClassName: PropTypes.bool
}

export default gridLayout;