import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

import GridLayout from "../gridLayout/GridLayout"

import commonStyles from "../../styles/common.module.css"
import subscribeStyles from "./_contactUs.module.css"
import { graphql, useStaticQuery } from "gatsby"

const Subscribe = props => {
  const { onSubscribe } = props
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          courses
        }
      }
    }
  `)

  const courses = query.site.siteMetadata.courses

  const [form, setForm] = useState({
    email: "",
    mobile: "",
    name: "",
    preferredCourse: "",
  })
  const [course, setCourse] = useState("")
  const [showCourse, setShowCourse] = useState([])
  const [showCourseDropdown, setShowCourseDropdown] = useState(false)
  const dropdownRef = useRef()
  let currentActive = -1

  // const [courseSearch, setCourseSearch] = useState("")

  const onSubmit = evt => {
    evt.preventDefault()
    onSubscribe(form)
  }

  const onChange = e => {
    const formClone = form
    formClone[e.target.name] = e.target.value
    setForm(formClone)
  }

  // auto complete textbox
  const onPerferredCourseChange = evt => {
    const courseSearch = evt.target.value
    const formClone = form

    setShowCourseDropdown(true)

    if (courseSearch !== "") {
      setShowCourse(
        courses.filter(course =>
          course.toLowerCase().includes(courseSearch.toLowerCase())
        )
      )
    } else {
      setShowCourse([])
    }
    setCourse(courseSearch)

    formClone.preferredCourse = courseSearch
    setForm(formClone)
  }

  const onCourseKeydown = evt => {
    if (course === "" || dropdownRef.current === null) return

    const dropdownChildren = dropdownRef.current.children
    if (evt.keyCode === 40) {
      currentActive++
      if (currentActive === dropdownChildren.length) currentActive = 0

      // removing "Active" class
      if (currentActive === 0) {
        if (
          dropdownChildren[dropdownChildren.length - 1].classList.value !== ""
        )
          dropdownChildren[dropdownChildren.length - 1].classList.remove(
            `${subscribeStyles.active}`
          )
      } else if (currentActive > 0) {
        dropdownChildren[currentActive - 1].classList.remove(
          `${subscribeStyles.active}`
        )
      }
      // adding "Active" class
      dropdownChildren[currentActive].classList.add(`${subscribeStyles.active}`)
    } else if (evt.keyCode === 38) {
      if (currentActive === -1 || currentActive === 0)
        currentActive = dropdownChildren.length
      currentActive--

      if (currentActive === dropdownChildren.length - 1) {
        if (dropdownChildren[0].classList.value !== "")
          dropdownChildren[0].classList.remove(`${subscribeStyles.active}`)
      } else
        dropdownChildren[currentActive + 1].classList.remove(
          `${subscribeStyles.active}`
        )

      dropdownChildren[currentActive].classList.add(`${subscribeStyles.active}`)
    } else if (evt.keyCode === 13) {
      const getCourse = showCourse[currentActive]
      const formClone = form

      if (getCourse) {
        setCourse(getCourse)
        formClone.preferredCourse = getCourse
        setForm(formClone)
      }
      setShowCourseDropdown(false)
    }
  }

  const selectCourse = (index) => {
    const getCourse = showCourse[index]
    const formClone = form
    setCourse(getCourse)
    formClone.preferredCourse = getCourse
    setForm(formClone)

    setShowCourseDropdown(false)

  }

  return (
  <div className={`${commonStyles.container} ${commonStyles.padding_t_100} ${commonStyles.padding_b_100}`} id="contactForm">
      <GridLayout applyContainerClassName={false}>
        <div className="layout_lg_12">
          <div className={subscribeStyles.bgFormContainer}>
            <p className={subscribeStyles.para2}>Never miss a course.</p>
            <form onSubmit={onSubmit}>
              <GridLayout>
                <div className={`layout_md_6 ${commonStyles.margin_b_15}`}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name*"
                    className={subscribeStyles.inputItem}
                    onChange={onChange}
                    required={true}
                  />
                </div>
                <div className={`layout_md_6 ${commonStyles.margin_b_15}`}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    className={subscribeStyles.inputItem}
                    onChange={onChange}
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  />
                </div>
                <div className={`layout_md_4 ${commonStyles.margin_b_15}`}>
                  <input
                    type="text"
                    name="mobile"
                    placeholder="Your Mobile No*"
                    className={subscribeStyles.inputItem}
                    onChange={onChange}
                    required={true}
                    pattern="^(\d{10})$"
                  />
                </div>
                <div className={`layout_md_4 ${commonStyles.margin_b_15}`}>
                  <div className={subscribeStyles.auto_complete_textbox}>
                    <input
                      type="text"
                      name="preferredCourse"
                      placeholder="Preferred Course*"
                      className={subscribeStyles.inputItem}
                      onChange={onPerferredCourseChange}
                      onKeyDown={onCourseKeydown}
                      required={true}
                      spellCheck={true}
                      value={course}
                    />
                    {showCourseDropdown ? (
                      <nav className={subscribeStyles.auto_complete_dropdown}>
                        <ul ref={dropdownRef}>
                          {showCourse.map((course, index) => (
                            <li onClick={()=>selectCourse(index)} key={index}>{course}</li>
                          ))}
                        </ul>
                      </nav>
                    ) : null}
                  </div>
                </div>
                <div className={"layout_md_4"}>
                  <button className={subscribeStyles.subBtn}>
                    <p className={subscribeStyles.subBtn_text}>SUBMIT</p>
                  </button>
                </div>
              </GridLayout>
            </form>
          </div>
        </div>
      </GridLayout>
    </div>
  )
}

Subscribe.propTypes = {
  onSubscribe: PropTypes.func,
}

export default Subscribe
