import React from "react"
import commonStyles from "../../styles/common.module.css"
import footerStyles from "./_footer.module.css"
import GridLayout from "../gridLayout/GridLayout"

import "../../fonts/fontawesome-free-5.13.0-web/css/all.css"

const Footer = () => {
  const emptyLink = "#"

  return (
    <div className={footerStyles.footer_bg} id="contact">
      <div className={commonStyles.container}>
        <div className={footerStyles.total}>
          <GridLayout>
            <div className={`layout_lg_4`}>
              <aside className={footerStyles.fspace}>
                <div className={footerStyles.header}>
                  <h3 className={footerStyles.title}>Address</h3>
                  <span></span>
                </div>
                <div className={footerStyles.content1}>
                  <img
                    src={require("../../images/talento_logo_white.png")}
                    alt="talento"
                    className={footerStyles.logo}
                  />
                  <div className={footerStyles.address}>
                    <div className={footerStyles.icon}>
                      <i className="fa fa-map-marker-alt"></i>
                    </div>
                    <div className={footerStyles.mediaBody}>
                      <address className={footerStyles.addr}>
                        Talento Academy
                        <br />
                        <abbr className={footerStyles.addr1}>
                          10 F2 Second Floor, Trivandrum Road, Next to
                          <br /> Thangamayil jewellery, Palayamkottai, <br />
                          Tirunelveli 627002 <br />
                          Contact:{" "}
                          <a
                            href="tel:91507-54631"
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            91507 54631
                          </a>
                        </abbr>
                      </address>
                    </div>
                    <div className={footerStyles["mail"]}>
                      <i className="far fa-envelope"></i>
                      Mail Us: <a href="mailto:abs.talento@gmail.com">abs.talento@gmail.com</a>
                    </div>
                    <div className={footerStyles["links"]}>
                      <a href="https://www.facebook.com/abspl.co">
                        <div className={footerStyles["link"]}>
                          <i className="fab fa-facebook-f"></i>
                        </div>
                      </a>
                      {/* <a href="https://www.twitter.com/"><div className={footerStyles["link"]}>
                        <i className="fab fa-twitter"></i>
                      </div></a> */}
                      <a href="https://in.linkedin.com/company/alpha-business-solutions-private-limited">
                        <div className={footerStyles["link"]}>
                          <i className="fab fa-linkedin-in"></i>
                        </div>
                      </a>
                      <a href="https://www.instagram.com/talento_academy/">
                        <div className={footerStyles["link"]}>
                          <i className="fab fa-instagram"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <div className={`layout_lg_4`}>
              <aside className={footerStyles.fspace}>
                <div className={footerStyles.header}>
                  <h3 className={footerStyles.title}>Valuable Courses</h3>
                  <span></span>
                </div>
                <ul className={footerStyles.list}>
                  <li className={footerStyles.li1}>
                    <a href={emptyLink} className={footerStyles.a1}>
                      - Full Stack Developer
                    </a>
                  </li>
                  <li className={footerStyles.li1}>
                    <a href={emptyLink} className={footerStyles.a1}>
                      - Proficient Programmer
                    </a>
                  </li>
                  <li className={footerStyles.li1}>
                    <a href={emptyLink} className={footerStyles.a1}>
                      - Object Oriented App Developer
                    </a>
                  </li>
                  <li className={footerStyles.li1}>
                    <a href={emptyLink} className={footerStyles.a1}>
                      - Oracle Certified Java Course
                    </a>
                  </li>
                </ul>
              </aside>
            </div>
            <div className={`layout_lg_4`}>
              <aside className={footerStyles.fspace}>
                <div className={footerStyles.header}>
                  <h3 className={footerStyles.title}>Gallery</h3>
                  <span></span>
                </div>
                <div className={footerStyles.gallery}>
                  <div className={footerStyles.gall1}>
                    <div className={footerStyles.gall2}>
                      <a href={emptyLink} className={footerStyles.gallImg}>
                        <img
                          src={require("../../images/gallery_images/1.jpg")}
                          alt="gallery-1"
                        />
                      </a>
                    </div>
                    <div className={footerStyles.gallTxt}>
                      C, Java, Python Classes
                    </div>
                  </div>
                  <div className={footerStyles.gall1}>
                    <div className={footerStyles.gall2}>
                      <a href={emptyLink} className={footerStyles.gallImg}>
                        <img
                          src={require("../../images/gallery_images/2.jpg")}
                          alt="gallery-2"
                        />
                      </a>
                    </div>
                    <div className={footerStyles.gallTxt}>Java Class</div>
                  </div>
                  <div className={footerStyles.gall1}>
                    <div className={footerStyles.gall2}>
                      <a href={emptyLink} className={footerStyles.gallImg}>
                        <img
                          src={require("../../images/gallery_images/3.jpg")}
                          alt="gallery-3"
                        />
                      </a>
                    </div>
                    <div className={footerStyles.gallTxt}>Python Class</div>
                  </div>
                  <div className={footerStyles.gall1}>
                    <div className={footerStyles.gall2}>
                      <a href={emptyLink} className={footerStyles.gallImg}>
                        <img
                          src={require("../../images/gallery_images/4.jpg")}
                          alt="gallery-4"
                        />
                      </a>
                    </div>
                    <div className={footerStyles.gallTxt}>
                      Summer Inplant Classes
                    </div>
                  </div>
                  <div className={footerStyles.gall1}>
                    <div className={footerStyles.gall2}>
                      <a href={emptyLink} className={footerStyles.gallImg}>
                        <img
                          src={require("../../images/gallery_images/5.jpg")}
                          alt="gallery-5"
                        />
                      </a>
                    </div>
                    <div className={footerStyles.gallTxt}>Android Workshop</div>
                  </div>
                  <div className={footerStyles.gall1}>
                    <div className={footerStyles.gall2}>
                      <a href={emptyLink} className={footerStyles.gallImg}>
                        <img
                          src={require("../../images/gallery_images/6.jpg")}
                          alt="gallery-6"
                        />
                      </a>
                    </div>
                    <div className={footerStyles.gallTxt}>Robotics Class</div>
                  </div>
                </div>
              </aside>
            </div>
          </GridLayout>
          <div className={footerStyles.row1}>
            <div className={footerStyles.content2}>
              <div className={footerStyles.info}>
                <p>
                  Copyright © 2017-2020 Talento Academy All right reserved by{" "}
                  <a href="http://www.alphabsolutions.com">ABS</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
