import talentoStyles from "../styles/talento.module.css"

export default (className) => {
    switch(className){
        case "webDevelopment" : return talentoStyles.web_dev_category
        case "appDevelopment" : return talentoStyles.app_dev_category
        case "gaming" : return talentoStyles.gaming_category
        case "designing" : return talentoStyles.designing_category
        case "robotics" : return talentoStyles.robotics_category
        case "mncTraining" : return talentoStyles.mnc_training_category
        default : return talentoStyles.web_dev_category
    }
}