import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

// import NewsEvents from "../components/newsEvents/Newsevents"
import { graphql } from "gatsby"
import Subscribe from "../components/contactUs/ContactUs"
import Course from "../components/course/Course"
import Banner from "../components/banner/Banner"
// import JoinUs from "../components/joinus/JoinUs"
import Feature from "../components/feature/Feature"
import WhyUs from "../components/whyus/WhyUs"

const IndexPage = ({ data }) => {

  //Header
  const onCategory = (catagory) => {
    console.log("Selected Category", catagory)
  }
  const onSearch = (search) => {
    console.log("Searched Word",search)
  }

  //banner
  const onCourse = () => {
    console.log("course clicked")
    window.location.hash = ""
    window.location.hash = "featuredCourse"
  }
  const onContact = () => {
    console.log("contact clicked")
    window.location.href="tel:91507-54631"
  }

  //NewsEvent List
  // const newsEventsArr = [
  //   {
  //     catagory:"webDevelopment",
  //     blogDate:"25/06/2020",
  //     image:data.newsImage1.childImageSharp.fluid,
  //     title:"Survey reveals most used CSS tools",
  //     description:"Creating software atailored to my games development gaming",
  //     link:""
  //   },
  //   {
  //     catagory:"webDevelopment",
  //     blogDate:"25/06/2020",
  //     image:data.newsImage2.childImageSharp.fluid,
  //     title:"Survey reveals most used CSS tools",
  //     description:"Creating software atailored to my games development gaming",
  //     link:""
  //   },
  //   {
  //     catagory:"webDevelopment",
  //     blogDate:"16/11/2019",
  //     image:data.newsImage1.childImageSharp.fluid,
  //     title:"How to Make a CSS-Only Carousel",
  //     description:"Creating a simple carousel with css only",
  //     link:""
  //   },
  //   {
  //     catagory:"webDevelopment",
  //     blogDate:"16/11/2019",
  //     image:data.newsImage1.childImageSharp.fluid,
  //     title:"How to Make a CSS-Only Carousel",
  //     description:"Creating a simple carousel with css only",
  //     link:""
  //   },
  //   {
  //     catagory:"webDevelopment",
  //     blogDate:"16/11/2019",
  //     image:data.newsImage1.childImageSharp.fluid,
  //     title:"How to Make a CSS-Only Carousel",
  //     description:"Creating a simple carousel with css only",
  //     link:""
  //   },
  //   {
  //     catagory:"webDevelopment",
  //     blogDate:"16/11/2019",
  //     image:data.newsImage1.childImageSharp.fluid,
  //     title:"How to Make a CSS-Only Carousel",
  //     description:"Creating a simple carousel with css only",
  //     link:""
  //   }
  // ]

  //feature List
  const featureContentArr = [
    {
      heading: "Programmatic Coding <br />(Java & Python)",
      img: data.featureDeveloperImage.childImageSharp.fluid,
      category:"mncTraining"
    },
    {
      heading: "Full Stack Development (MERN)",
      img: data.featureAppDevMernImage.childImageSharp.fluid,
      category:"webDevelopment"
    },
    {
      heading: "UI/UX Designer",
      img: data.featureUiImage.childImageSharp.fluid,
      category:"designing"
    },
    {
      heading: "IOT and Robotics",
      img: data.featureRoboticsImage.childImageSharp.fluid,
      category:"robotics"
    },
    {
      heading: "Full Stack Development (MEAN)",
      img: data.featureAppDevImage.childImageSharp.fluid,
      category:"webDevelopment"
    },
    {
      heading: "Built with Unity Engine <br />(10+ Mini Projects)",
      img: data.featureGamingImage.childImageSharp.fluid,
      category:"gaming"
    },
  ]

  //whyus
  const whyarr = [
    {
      quote:
        "PROGMATIC is a very good initative by Talento Academy.It helped me to over come my fears in my technical exam and I am so happy that I got placed in Tech Mahindra.",
      author: "Akansha Singh",
      designation: "FX CSE 2017",
    },
    {
      quote:
        `Doing Python, Django, Html, CSS, JS. A place to experience the "Art of Teaching" (Practical Training). Excellent!!!!.`,
      author: "Kabilan",
      designation: "Team Lead",
    },
    {
      quote:
        "Best place to learn. Friendly environment. Friendly Staff, I learnt Javascript & Python. Really it's nice and worth to learn here.",
      author: "Chevvanthi Raji",
      designation: "Student",
    },
  ]
  const onJoinUsClick = () => {
    console.log("Join us button clicked")
    window.location.hash = ""
    window.location.hash = "contactForm"
  }

  //subscribe
  const onSubscribe = (email) => {
    console.log("Subscription email -->",email)
  }

  //JoinUs
  // const onPartner = () => {
  //   console.log("Apply as Partner")
  // }
  // const onInstructor = () => {
  //   console.log("Apply for Instructor")
  // }

  const metaDescription = [
    "Talento Academy: Software Training Institute in Tirunelveli",
    "Best Software Training and Placement Institute in Tirunelveli",
    "Top Computer Software Training Institutes in Tirunelveli",
    "Top 10 Software Training Institutes in Tirunelveli",
    "Top Software Training Institutes in Palayamkottai, Tirunelveli",
    "Best IT & Software Colleges in Tirunelveli",
    "Software Engineering Training in Tirunelveli",
    "Software training institutes in Tirunelveli",
    "Computer Software Training in Tirunelveli"
  ]

  return (
    <Layout onCategory={onCategory} onSearch={onSearch}>
      <Helmet>
        <title>Talento Academy | Best Software Training Academy in Tirunelveli</title>
        <meta name="description" content={`${metaDescription.join(', ')}`} />
      </Helmet>
      <Banner onCourse={onCourse} onContact={onContact}/>
      <Course />
      <Feature featureList={featureContentArr} />
      {/* <NewsEvents mainLimit={2} newsEvents={newsEventsArr} /> */}
      <WhyUs whyUs={whyarr} onJoinUsClick={onJoinUsClick} />
      <Subscribe onSubscribe={onSubscribe}/>
      {/* <JoinUs onInstructor={onInstructor} onPartner={onPartner} /> */}
    </Layout>
  )
}

export const query = graphql`
  {
    newsImage1: file(
      relativePath: { eq: "news_images/feature-02.png" }
    ){
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsImage2: file(
      relativePath: { eq: "news_images/feature-03.png" }
    ){
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureDeveloperImage: file(
      relativePath: { eq: "feature_images/developer.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureAppDevImage: file(
      relativePath: { eq: "feature_images/app development.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureAppDevMernImage: file(
      relativePath: { eq: "feature_images/app development(mern).png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureUiImage: file(
      relativePath: { eq: "feature_images/designing.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureRoboticsImage: file(
      relativePath: { eq: "feature_images/robotics.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureGamingImage: file(
      relativePath: { eq: "feature_images/gaming.png" }
    ) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
