import React from "react"
import PropTypes from "prop-types"

import commonStyles from "../../styles/common.module.css"
import bannerStyles from "./_banner.module.css"

const BannerHero = (props) => {

    const { onContact, onCourse } = props
    
    const onContactClick = () => {
        onContact()
    }

    const onCourseClick = () => {
        onCourse()
    }

    return (
        <div className={bannerStyles.hero_background_color} id="home">
            <div className={bannerStyles.hero_background}>
                <div className={bannerStyles.hero_background_image}/>
                <div style={{zIndex:"1",position:"relative"}} className={[commonStyles.container, commonStyles.align_items_center].join(" ")}>
                    <div className={bannerStyles.title}>
                        <p>#Improving lives through learning</p>
                        <h1>Welcome to  <br/>Talento Academy.</h1>
                        <p>Talento Academy is rated as the one of the best IT Education & Training Institute in Tirunelveli, we the group of individuals devoted to excellence in teaching. We think disruptively to deliver tailor-made courses for student and professionals, all while hoping to make some positive impact in the educational community. We teach the magic of coding by hands-on and by the best practices followed in the software industry.</p>
                    </div>
                    <div className={bannerStyles.button_container}>
                        <button className={bannerStyles.course_button} onClick={onCourseClick}>view courses</button> <button className={bannerStyles.contact_button} onClick={onContactClick}>contact us</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

BannerHero.propTypes = {
    onContact: PropTypes.func,
    onCourse: PropTypes.func
}

export default BannerHero