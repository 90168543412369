import React, { useEffect, useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import "../../fonts/fontawesome-free-5.13.0-web/css/all.css"
import commonStyles from "../../styles/common.module.css"
import headerStyles from "./_header.module.css"

const Header = (props) => {

  const { currentActive, onLink, onSearch, onCategory } = props

  const [isScrolled, setIsScrolled] = useState(false)
  const [showDropdown, setShowDropdown] = useState(true)
  const [showCatagory, setShowCatagory] = useState(false)
  const [search, setSearch] = useState("")
  const collaspsedMenuRef = useRef();

  const queryData = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "talento_logo_white.png" }) {
        childImageSharp {
          fixed(pngQuality: 10, width: 160) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          headerListOptions {
            name
            innerMenu{
              name
            }
          }
        }
      }
    }
  `)

  const headerListOptions = queryData.site.siteMetadata.headerListOptions

  useEffect(() => {
    window.addEventListener("scroll", onScrolled)

    return () => window.removeEventListener("scroll", onScrolled)
  }, [])

  const clickEvent = () => {
    setShowCatagory(!showCatagory)
  }

  const onScrolled = () => {
    if (window.pageYOffset === 0) {
      setIsScrolled(false)
    } else {
      setIsScrolled(true)
    }
  }

  const onDropdownClick = () => {
    setShowDropdown(!showDropdown)
    if (showDropdown) {
      collaspsedMenuRef.current.style.height = `${(headerListOptions.length * 65)}px`
    }
    else {
      collaspsedMenuRef.current.style.height = `0`
    }

  }

  const onCategorySelect = (catagory) => {
    onCategory(catagory)
  }

  //search
  const onChange = (evt) => {
    setSearch(evt.target.value)
  }
  const onKeyDown = (evt) => {
    if (evt.keyCode === 13) {
      if (search !== "") onSearch(search)
    }
  }

  return (
    <header
      className={[
        headerStyles.container,
        isScrolled ? headerStyles.sticky_header : null,
      ].join(" ")}
    >
      <div className={[commonStyles.max_width_1360, headerStyles.wrapper].join(" ")}>
        <div className={headerStyles.logo}>
          <a href="#home">
          <Img
            fixed={queryData.logoImage.childImageSharp.fixed}
            style={{ display: "block" }}
          />
          </a>
        </div>
        <div className={headerStyles.catagory_search}>
          <div className={headerStyles.button_wrap}>
            <button onClick={clickEvent}><i style={{ marginRight: "7px" }} className="fas fa-th"></i>Categories</button>
            <nav className={[headerStyles.catagory_list, (showCatagory) ? headerStyles.catagory_list_active : null].join(" ")}>
              <ul>
                <li><button onClick={() => onCategorySelect("development")}>Development</button></li>
                <li><button onClick={() => onCategorySelect("designing")}>Designing</button></li>
                <li><button onClick={() => onCategorySelect("gaming")}>Gaming</button></li>
                <li><button onClick={() => onCategorySelect("robotics")}>Robotics</button></li>
                <li><button onClick={() => onCategorySelect("mncTraining")}>MNC Training</button></li>
              </ul>
            </nav>
          </div>
          <div role="none" onClick={clickEvent} className={(showCatagory) ?headerStyles.catagory_list_wrapper: null}></div>
        </div>
        <div className={headerStyles.catagory_search}>
          <div className={headerStyles.input_wrap}><input placeholder="Search" onChange={onChange} onKeyDown={onKeyDown} /></div>
        </div>
        <div>
          <div className={headerStyles.linklists}>
            <ul>
              {headerListOptions.map((headerListOption, index) => (
                <li
                  className={[
                    headerStyles.linklist,
                    index === currentActive
                      ? headerStyles.linklist_active
                      : null,
                  ].join(" ")}
                  key={index}
                >
                  {" "}
                  {/* HeaderListOptions are loaded from "headerData.js" */}
                  <button onClick={() => onLink(headerListOption.name, index)}>{headerListOption.name}{headerListOption.innerMenu ? <i style={{ marginLeft: "7px" }} className="fas fa-chevron-down"></i> : null}</button>
                  {headerListOption.innerMenu ?
                    <nav className={headerStyles.inner_list}>
                      <ul>
                        {
                          headerListOption.innerMenu.map((menu, index) =>
                            <li key={index}>{menu.name}</li>
                          )
                        }
                      </ul>
                    </nav>
                    : null}
                </li>
              ))}
            </ul>
          </div>
          <div className={headerStyles.collapsedlinklist}>
            <button
              className={[
                headerStyles.collapsedlinklist_btn,
                (!showDropdown) ? headerStyles.collapsedlinklist_btn_expand : null
              ].join(" ")}
              onClick={onDropdownClick}
            >
              <span />
              <span />
              <span />
            </button>
            <nav
              className={[
                headerStyles.collapsedlinklist_dropdown,
              ].join(" ")}
              ref={collaspsedMenuRef}
            >
              <ul>
                {headerListOptions.map((headerListOption, index) => (
                  <li className={""} key={index}>
                    {" "}
                    <button onClick={() => onLink(headerListOption.name, index)} >{headerListOption.name}</button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  currentActive: PropTypes.number,
  onLink: PropTypes.func,
  onSearch: PropTypes.func,
  onCategory: PropTypes.func
}
export default Header
