import React, { useState, useRef } from "react"

// import tone1 from "../../audio/1.mp3"
// import tone2 from "../../audio/2.mp3"
// import tone3 from "../../audio/3.mp3"
// import tone4 from "../../audio/4.mp3"
// import tone5 from "../../audio/5.mp3"
// import tone6 from "../../audio/6.mp3"
// import tone7 from "../../audio/7.mp3"
// import tone8 from "../../audio/8.mp3"
// import tone9 from "../../audio/9.mp3"
// import tone10 from "../../audio/10.mp3"
// import tone11 from "../../audio/11.mp3"
// import tone12 from "../../audio/12.mp3"
// import tone13 from "../../audio/13.mp3"
// import tone14 from "../../audio/14.mp3"
// import tone15 from "../../audio/15.mp3"
// import tone16 from "../../audio/16.mp3"
// import tone17 from "../../audio/17.mp3"
// import tone18 from "../../audio/18.mp3"
// import tone19 from "../../audio/19.mp3"
// import toneb1 from "../../audio/b1.mp3"
// import toneb2 from "../../audio/b2.mp3"
// import toneb3 from "../../audio/b3.mp3"
// import toneb4 from "../../audio/b4.mp3"
// import toneb5 from "../../audio/b5.mp3"
// import toneb6 from "../../audio/b6.mp3"
// import toneb7 from "../../audio/b7.mp3"
// import toneb8 from "../../audio/b8.mp3"
// import toneb9 from "../../audio/b9.mp3"
// import toneb10 from "../../audio/b10.mp3"
// import toneb11 from "../../audio/b11.mp3"
// import toneb12 from "../../audio/b12.mp3"
// import toneb13 from "../../audio/b13.mp3"
// import toneb14 from "../../audio/b14.mp3"

import courseStyles from "./_course.module.css"

export default () => {
  const [pianoClass, setPianoClass] = useState(`${courseStyles.piano_desk}`)
  let timer = useRef()

    const tone1 = useRef()
    const tone2 = useRef()
    const tone3 = useRef()
    const tone4 = useRef()
    const tone5 = useRef()
    const tone6 = useRef()
    const tone7 = useRef()
    const tone8 = useRef()
    const tone9 = useRef()
    const tone10 = useRef()
    const tone11 = useRef()
    const tone12 = useRef()
    const tone13 = useRef()
    const tone14 = useRef()
    const tone15 = useRef()
    const tone16 = useRef()
    const tone17 = useRef()
    const tone18 = useRef()
    const tone19 = useRef()
    const toneb1 = useRef()
    const toneb2 = useRef()
    const toneb3 = useRef()
    const toneb4 = useRef()
    const toneb5 = useRef()
    const toneb6 = useRef()
    const toneb7 = useRef()
    const toneb8 = useRef()
    const toneb9 = useRef()
    const toneb10 = useRef()
    const toneb11 = useRef()
    const toneb12 = useRef()
    const toneb13 = useRef()
    const toneb14 = useRef()

  const onClick = section => {
    switch (section) {
      case "web": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.web}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      case "ui": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.ui}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      case "game": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.game}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      case "robot": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.robot}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      case "ai": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.ai}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      case "app": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.app}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      case "mobility": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.mobility}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      case "cloud": {
        clearTimeout(timer.current)
        setPianoClass(`${courseStyles.piano_desk} ${courseStyles.cloud}`)
        timer.current = setTimeout(() => {
          setPianoClass(`${courseStyles.piano_desk}`)
        }, 4000)
        break
      }
      default:
        return null
    }
  }

  const pianoTone = toneNo => {
    toneNo.current.currentTime=0
    toneNo.current.play()
  }

  return (
    <div className={courseStyles.piano}>
      <div className={pianoClass}/>
      <div className={courseStyles.keys_container}>
        <div
          onClick={() => pianoTone(tone1)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="b1"
          onClick={() => pianoTone(toneb1)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone2)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="b2"
          onClick={() => pianoTone(toneb2)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          data-key="1"
          onClick={() => {
            onClick("web")
            pianoTone(tone3)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_earth,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          data-key="b3"
          onClick={() => pianoTone(toneb3)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone4)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="2"
          onClick={() => {
            onClick("ui")
            pianoTone(tone5)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_uiux,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          data-key="b4"
          onClick={() => pianoTone(toneb4)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone6)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="b5"
          onClick={() => pianoTone(toneb5)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          data-key="3"
          onClick={() => {
            onClick("game")
            pianoTone(tone7)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_gamepad,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          data-key="b6"
          onClick={() => pianoTone(toneb6)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone8)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="4"
          onClick={() => {
            onClick("robot")
            pianoTone(tone9)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_robot,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          data-key="b7"
          onClick={() => pianoTone(toneb7)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone10)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="b8"
          onClick={() => pianoTone(toneb8)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          data-key="5"
          onClick={() => {
            onClick("ai")
            pianoTone(tone11)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_brain,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          data-key="b9"
          onClick={() => pianoTone(toneb9)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone12)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="6"
          onClick={() => {
            onClick("app")
            pianoTone(tone13)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_apps,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          data-key="b10"
          onClick={() => pianoTone(toneb10)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone14)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="b11"
          onClick={() => pianoTone(toneb11)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          data-key="7"
          onClick={() => {
            onClick("mobility")
            pianoTone(tone15)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_mobile,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          onClick={() => pianoTone(tone16)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="b12"
          onClick={() => pianoTone(toneb12)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          data-key="8"
          onClick={() => {
            onClick("cloud")
            pianoTone(tone17)
          }}
          className={courseStyles.key}
          role="none"
        >
          <span>
            <div
              className={[
                courseStyles.demo_mask_container,
                courseStyles.mask_cloud,
              ].join(" ")}
            >
              <div className={courseStyles.demo_mask}/>
            </div>
          </span>
        </div>
        <div
          data-key="b13"
          onClick={() => pianoTone(toneb13)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone18)}
          className={courseStyles.key}
          role="none"
        />
        <div
          data-key="b14"
          onClick={() => pianoTone(toneb14)}
          className={courseStyles.black_key}
          role="none"
        />
        <div
          onClick={() => pianoTone(tone19)}
          className={courseStyles.key}
          role="none"
        />
      </div>
      <>
      <audio preload="auto" ref={tone1} src={require("../../audio/1.mp3")} />
      <audio preload="auto" ref={tone2} src={require("../../audio/2.mp3")} />
      <audio preload="auto" ref={tone3} src={require("../../audio/3.mp3")} />
      <audio preload="auto" ref={tone4} src={require("../../audio/4.mp3")} />
      <audio preload="auto" ref={tone5} src={require("../../audio/5.mp3")} />
      <audio preload="auto" ref={tone6} src={require("../../audio/6.mp3")} />
      <audio preload="auto" ref={tone7} src={require("../../audio/7.mp3")} />
      <audio preload="auto" ref={tone8} src={require("../../audio/8.mp3")} />
      <audio preload="auto" ref={tone9} src={require("../../audio/9.mp3")} />
      <audio preload="auto" ref={tone10} src={require("../../audio/10.mp3")} />
      <audio preload="auto" ref={tone11} src={require("../../audio/11.mp3")} />
      <audio preload="auto" ref={tone12} src={require("../../audio/12.mp3")} />
      <audio preload="auto" ref={tone13} src={require("../../audio/13.mp3")} />
      <audio preload="auto" ref={tone14} src={require("../../audio/14.mp3")} />
      <audio preload="auto" ref={tone15} src={require("../../audio/15.mp3")} />
      <audio preload="auto" ref={tone16} src={require("../../audio/16.mp3")} />
      <audio preload="auto" ref={tone17} src={require("../../audio/17.mp3")} />
      <audio preload="auto" ref={tone18} src={require("../../audio/18.mp3")} />
      <audio preload="auto" ref={tone19} src={require("../../audio/19.mp3")} />
      <audio preload="auto" ref={toneb1} src={require("../../audio/b1.mp3")} />
      <audio preload="auto" ref={toneb2} src={require("../../audio/b2.mp3")} />
      <audio preload="auto" ref={toneb3} src={require("../../audio/b3.mp3")} />
      <audio preload="auto" ref={toneb4} src={require("../../audio/b4.mp3")} />
      <audio preload="auto" ref={toneb5} src={require("../../audio/b5.mp3")} />
      <audio preload="auto" ref={toneb6} src={require("../../audio/b6.mp3")} />
      <audio preload="auto" ref={toneb7} src={require("../../audio/b7.mp3")} />
      <audio preload="auto" ref={toneb8} src={require("../../audio/b8.mp3")} />
      <audio preload="auto" ref={toneb9} src={require("../../audio/b9.mp3")} />
      <audio preload="auto" ref={toneb10} src={require("../../audio/b10.mp3")} />
      <audio preload="auto" ref={toneb11} src={require("../../audio/b11.mp3")} />
      <audio preload="auto" ref={toneb12} src={require("../../audio/b12.mp3")} />
      <audio preload="auto" ref={toneb13} src={require("../../audio/b13.mp3")} />
      <audio preload="auto" ref={toneb14} src={require("../../audio/b14.mp3")} />
      </>
    </div>
  )
}
