import React, { useRef, useState } from "react"
import FeatureImg from "gatsby-image"

import GridLayout from "../gridLayout/GridLayout"

import getCategoryClass from "../../utils/getCategoryClass"

import commonStyles from "../../styles/common.module.css"
import featureStyles from "./_feature.module.css"

const Feature = props => {

  return (
    <div className={featureStyles.card}>
      <div className={featureStyles.totalContent}>
        <div className={featureStyles.button1}>
          <div className={getCategoryClass(props.category)}/>
        </div>
        <h4 className={featureStyles.head1}><div dangerouslySetInnerHTML={{ __html: props.heading}} /></h4>
        <FeatureImg fluid={props.image} className={featureStyles.img} />
      </div>
    </div>
  )
}
export default props => {
  const { featureList } = props
  const listRef = useRef()
  const currentActive = useRef(0)
  const [featureArr, setFeatureArr] = useState(featureList)

  const onListClick = (value, category) => {
    listRef.current.children[currentActive.current].classList.remove(
      `${featureStyles.list_active}`
    )
    listRef.current.children[value].classList.add(
      `${featureStyles.list_active}`
    )
    currentActive.current = value


    let featureCloneArr = [...featureList]
    featureCloneArr = featureCloneArr.filter(
      element =>{
        if(element.category==="webDevelopment"|| element.category==="appDevelopment") {
          if(category==="development") return true
        } 
        return element.category === category
      }
    )
    if (category === "all") {
      setFeatureArr(featureList)
    } else {
      setFeatureArr(featureCloneArr)
    }
  }

  return (
    <div className={featureStyles.bg} id="featuredCourse">
      <div
        className={[
          commonStyles.container,
          commonStyles.padding_t_100,
          commonStyles.padding_b_50,
        ].join(" ")}
      >
        <div className={featureStyles.heading}>
          <div className={featureStyles.title_wrapper}>
            <div className={featureStyles.title}>
              <p className={featureStyles.headerPara}>#Featured Courses</p>
              <h2>
                <strong>Featured Courses</strong>
              </h2>
            </div>
            <div className={featureStyles.subTitle}>
              <p>
                Explore all the featured courses from a wide range of categories
                to choose from.
              </p>
            </div>
          </div>
          <div className={featureStyles.featurebtn}>
            <a href="tel:91507-54631"><button className={featureStyles.button} type="button">
              CONTACT US
            </button></a>
          </div>
        </div>
        <div className={featureStyles.navItem}>
          <ul className={featureStyles.list} ref={listRef}>
            <li className={featureStyles.list_active}>
              <button onClick={() => onListClick(0, "all")}>All</button>
            </li>
            <li>
              <button onClick={() => onListClick(1, "development")}>
                Development
              </button>
            </li>
            <li>
              <button onClick={() => onListClick(2, "designing")}>
                Designing
              </button>
            </li>
            <li>
              <button onClick={() => onListClick(3, "gaming")}>Gaming</button>
            </li>
            <li>
              <button onClick={() => onListClick(4, "robotics")}>
                Robotics
              </button>
            </li>
            <li>
              <button onClick={() => onListClick(5, "mncTraining")}>
                MNC Training
              </button>
            </li>
          </ul>
        </div>
        <GridLayout>
          {featureArr.length > 0 ? (
            featureArr.map((content, index) => (
              <div className={`layout_lg_4 ${featureStyles.card_container}`} key={index}>
                <Feature
                  image={content.img}
                  category={content.category}
                  heading={content.heading}
                  key={index}
                  index={index}
                />
              </div>
            ))
          ) : (
            <p
              style={{ textAlign: "center", width: "100%", marginTop: "10px" }}
            >
              No Courses Found
            </p>
          )}
        </GridLayout>
      </div>
    </div>
  )
}
