import React from "react"

import GridLayout from "../gridLayout/GridLayout"

import commonStyles from "../../styles/common.module.css"
import bannerStyles from "./_banner.module.css"

export default () => {
    return(
        <div className={commonStyles.container} id="about">
            <GridLayout applyContainerClassName={false}>
                <div className={`layout_lg_6 ${bannerStyles.content_title}`}>
                    <h1><strong>We are Talento.<br/> A career development center.</strong></h1>
                    <p className={bannerStyles.content_para}>At Talento, we believe everyone should have the opportunity to create progress through technology and develop this skills of tomorrow. With assessments, learing paths and courses authored by industry experts. Get a personal gimpse into careers and mindsets of leading experts who are solving complex challenges and navigating evolving field opportunities.</p>
                </div>
                <div className={`layout_lg_6 ${commonStyles.align_items_end}`}>
                    <GridLayout applyContainerClassName={false}>
                        <div className={`layout_md_4`}>
                            <div className={`${bannerStyles.content_card_container} ${bannerStyles.mask_certificate}`}>
                                <div className={bannerStyles.content_mask_container}>
                                    <div className={bannerStyles.content_mask} />
                                </div>
                                <h3><strong>10+</strong></h3>
                                <p>Courses</p>
                            </div>
                        </div>
                        <div className="layout_md_4">
                            <div className={`${bannerStyles.content_card_container} ${bannerStyles.mask_study}`}>
                                <div className={bannerStyles.content_mask_container}>
                                    <div className={bannerStyles.content_mask} />
                                </div>
                                <h3><strong>2000+</strong></h3>
                                <p>Students</p>
                            </div>
                        </div>
                        <div className="layout_md_4">
                            <div className={`${bannerStyles.content_card_container} ${bannerStyles.mask_conference}`}>
                                <div className={bannerStyles.content_mask_container}>
                                    <div className={bannerStyles.content_mask} />
                                </div>
                                <h3><strong>500+</strong></h3>
                                <p>Classes Completed</p>
                            </div>
                        </div>
                    </GridLayout>
                </div>
            </GridLayout>
        </div>
    )
}