import React from "react"
import PropTypes from "prop-types"
import WhyUsSection from "./WhyUsSection"
import WhyUsSection2 from "./whyUsSection2"
import WhyUsSection3 from "./WhyUsSection3"

const WhyUs = ({whyUs, onJoinUsClick}) => {
    return (
        <>
            <WhyUsSection />   
            <WhyUsSection2 onJoinUsClick={onJoinUsClick}/>
            <WhyUsSection3 whyUs={whyUs}/>
        </>
    )
}

WhyUs.propTypes = {
    whyUs: PropTypes.array,
    onJoinUsClick: PropTypes.func
}

export default WhyUs;