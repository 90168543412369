import React from "react"

import commonStyles from "../../styles/common.module.css"
import courseStyles from "./_course.module.css"
import Piano from "./Piano"

const Course = () => {

  return (
    <div
      className={[commonStyles.container, commonStyles.padding_b_100].join(" ")}
      id="course"
    >
      <div className={courseStyles.heading}>
        <div className={courseStyles.title}>
          <p>#Course Categories</p>
          <h2>
            <strong>Course Categories</strong>
          </h2>
        </div>
        <div className={courseStyles.button1}>
          <a href="#featuredCourse"><button type="button" className={courseStyles.btn}>
            BROWSE ALL
          </button></a>
        </div>
      </div>
        <Piano />
    </div>
  )
}
export default Course
