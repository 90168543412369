import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

import commonStyles from "../../styles/common.module.css"
import whyUsStyles from "./_whyUs.module.css"

const WhyUsSection3 = props => {
  const { whyUs } = props

  //variables
  const arr = [...whyUs]
  const slideRef = useRef()
  const dotRef = useRef()
  const counter = useRef(1)
  let size, dotRefChildren, slideRefChildren, timerInterval, sliding=0, startClientX, currentClientX

  //clone array
  arr.splice(0, 0, arr[arr.length - 1])
  arr.splice(arr.length, 0, arr[1], arr[2])

  //callback ref
  useEffect(() => {
    const timer = setTimeout(() => {
      //eslint-disable-next-line react-hooks/exhaustive-deps
      dotRefChildren = dotRef.current.children
      //eslint-disable-next-line react-hooks/exhaustive-deps
      slideRefChildren = slideRef.current.children
      //eslint-disable-next-line react-hooks/exhaustive-deps
      size = slideRef.current.children[0].clientWidth + (window.innerWidth>567?40:0)   // the 40 represents the margin right:40

      slideRef.current.style.transform = `translateX(${
        -size * counter.current
      }px)`
      slideRef.current.style.transition = "transform 0.4s ease-in-out"
      
      slideRefChildren[0+1].classList.add(`${whyUsStyles.slide_active}`)
      dotRefChildren[0].classList.add(`${whyUsStyles.dot_active}`)
    }, 250)

    const resizeEvent = () => {
        size = slideRef.current.children[0].clientWidth + (window.innerWidth>567?40:0)
        slideRef.current.style.transform = `translateX(${
          -size * counter.current
        }px)`
        slideRef.current.style.transition = "transform 0.4s ease-in-out"
    } 

    window.addEventListener("resize", resizeEvent)

    slideRef.current.addEventListener("transitionstart", () => {
      clearTimeout(timerInterval)
      timerInterval = setInterval(() => onSlideClick(counter.current + 1), 5000)
    }) // restart time interval on transistionStart
    slideRef.current.addEventListener("transitionend", transitionEnd)

    //mousetouch listeners
    slideRef.current.addEventListener("mousedown", mouseSlideStart)
    slideRef.current.addEventListener("mousemove", mouseSlide)
    slideRef.current.addEventListener("mouseup", mouseSlideEnd)
    slideRef.current.addEventListener("mouseleave", mouseSlideEnd)

    //mobiletouch listeners
    slideRef.current.addEventListener("touchstart", mouseSlideStart)
    slideRef.current.addEventListener("touchmove", mouseSlide)
    slideRef.current.addEventListener("touchend", mouseSlideEnd)

    //eslint-disable-next-line react-hooks/exhaustive-deps
    timerInterval = setInterval(() => onSlideClick(counter.current + 1), 5000)

    return () => {
      clearTimeout(timer)
      clearTimeout(timerInterval)
      window.removeEventListener("resize", resizeEvent)
      slideRef.current.removeEventListener("transitionstart", () => {
        clearTimeout(timerInterval)
        timerInterval = setInterval(
          () => onSlideClick(counter.current + 1),
          5000
        )
      })
      //eslint-disable-next-line react-hooks/exhaustive-deps
      slideRef.current.removeEventListener("transitionend", () => transitionEnd)
    }
  }, [])

  //transition listeners
  const transitionEnd = () => {
    if (counter.current === 0) {
      counter.current = whyUs.length
      slideRef.current.style.transition = "none"
      slideRef.current.style.transform = `translateX(${
        -size * counter.current
      }px)`
    }
    if (counter.current === whyUs.length + 1) {
      counter.current = 1
      slideRef.current.style.transition = "none"
      slideRef.current.style.transform = `translateX(${
        -size * counter.current
      }px)`
    }
  }

  // button listeners
  const onSlideClick = slideNo => {
    if(counter.current > whyUs.length) return
    dotRefChildren[counter.current - 1].classList.remove(
      `${whyUsStyles.dot_active}`
    )
    slideRefChildren[(counter.current - 1)+1].classList.remove(
      `${whyUsStyles.slide_active}`
    )
    if (slideNo === whyUs.length + 1){
      dotRefChildren[0].classList.add(`${whyUsStyles.dot_active}`)
      slideRefChildren[0+1].classList.add(`${whyUsStyles.slide_active}`)
    }
    else if (slideNo === 0){
      dotRefChildren[whyUs.length - 1].classList.add(
        `${whyUsStyles.dot_active}`
      )
      slideRefChildren[(whyUs.length - 1)+1].classList.add(`${whyUsStyles.slide_active}`)
    }
    else{ 
      dotRefChildren[slideNo - 1].classList.add(`${whyUsStyles.dot_active}`)
      slideRefChildren[(slideNo - 1)+1].classList.add(`${whyUsStyles.slide_active}`)
    }
    counter.current = slideNo
    slideRef.current.style.transition = "transform 0.4s ease-in-out"
    slideRef.current.style.transform = `translateX(${
      -size * counter.current
    }px)`
  }

  //touch Listeners
  const mouseSlideStart = event => {
    if (sliding === 0) {
      if (counter.current !== 0 && counter.current !== whyUs.length+1) {
        if (event.type === "mousedown") startClientX = event.clientX
        else if (event.type === "touchstart") startClientX = event.touches[0].clientX
        sliding = 1
      }
    }
  }
  const mouseSlide = event => {
    event.preventDefault()
    if (event.which === 1 || event.which === 0) {
      if (sliding === 1) {
        slideRef.current.style.transition = `none`
        if (event.type === "mousemove")
          currentClientX = event.clientX - startClientX
        else if (event.type === "touchmove")
          currentClientX = event.touches[0].clientX - startClientX
        slideRef.current.style.transform = `translateX(${(-size * counter.current) +
          currentClientX}px)`
      }
    }
  }
  const mouseSlideEnd = () => {
    if (sliding === 1) {
      slideRef.current.style.transition = `transform 0.4s ease-in-out`
      sliding = 0
      if (currentClientX <= -100) {
        onSlideClick(counter.current + 1)
      } else if (currentClientX >= 100) {
        onSlideClick(counter.current - 1)
      } else
        slideRef.current.style.transform = `translateX(${-size * counter.current}px)`
      currentClientX = 0
    }
  }

  return (
    <div className={commonStyles.padding_t_100}>
      <div className={commonStyles.container}>
        <div className={whyUsStyles.title}>
          <h4>#WhyTalento</h4>
          <h2>
            <strong>Why Study with Talento?</strong>
          </h2>
        </div>
      </div>
      <div className={commonStyles.overflow_hidden} style={{padding:"10px 0"}}>
        <div className={whyUsStyles.slider_container}>
          <div
            className={whyUsStyles.slider}
            style={{ width: `${520 * arr.length}px` }}
            ref={slideRef}
          >
            {arr
              ? arr.map((value, key) => (
                  <div className={whyUsStyles.slide} key={key}>
                    <div className={whyUsStyles.slide_content}>
                      <p>{value.quote}</p>
                      <h4><strong>{value.author}</strong></h4>
                      <p className={whyUsStyles.slide_content_designation}>{value.designation}</p>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <div className={whyUsStyles.dots} ref={dotRef}>
        {whyUs
          ? whyUs.map((_, index) => (
              <div
                className={whyUsStyles.dot}
                onClick={() => onSlideClick(index + 1)}
                key={index}
                role="none"
              />
            ))
          : null}
      </div>
    </div>
  )
}

WhyUsSection3.propTypes = {
  whyUs: PropTypes.array,
}

export default WhyUsSection3
