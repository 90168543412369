/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Header from "./header/Header"
import Footer from "./footer/Footer"

import "./layout.css"
import talentoStyles from "../styles/talento.module.css"
import "../fonts/fontawesome-free-5.13.0-web/css/all.css"

const Layout = ({ children, onCategory, onSearch }) => {
  
  const [isMobile, setIsMobile] = useState(false)

  useEffect(()=>{
    setIsMobile(detectMob())
  },[])
  
  const onLink = link => {
    window.location.hash = link.toLowerCase()
  }

  const detectMob = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem)
    })
  }

  const whatsappMobileNo = "8531887272";

  const mobileWhatsppLink = `whatsapp://send?phone=${whatsappMobileNo}&text= Hi,`
  const webWhatsppLink = `https://web.whatsapp.com/send?phone=${whatsappMobileNo}&text= Hi,`

  return (
    <>
      <Header onCategory={onCategory} onSearch={onSearch} onLink={onLink} />
      <main>
        {children}
        <a href={isMobile?mobileWhatsppLink:webWhatsppLink}>
          <div className={talentoStyles.whatsapp_fixed}>
            <i className="fab fa-whatsapp"></i>
            <span className={talentoStyles.ripple}></span>
          </div>
        </a>
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  onSearch: PropTypes.func,
  onCategory: PropTypes.func,
}

export default Layout
